.categorySelect fieldset {
    border: 1px solid #D0D0D0 !important;
}

.productTable td {
    padding-bottom: 8px;
    padding-top: 8px;
}

.productOption div.MuiPaper-root {
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

